.navbar {
    width: 100%;
    z-index: 1;
    background-color: rgba(249, 249, 249, 1);
    display: flex;
    flex-direction: column;
    position: fixed;



    .navbar-nav {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: rgba(249, 249, 249, 1) !important;
        margin: 3rem;

        .flexNav {
            display: flex;
        }

        li {
            width: 15rem;

            a {
                color: rgba(127, 2, 145, 1);
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                padding-top: 0.4rem;
                padding-bottom: 0rem;

            }

        }


        .contact {
            color: white;
            text-align: center;
            background-color: rgba(127, 2, 145, 1) !important;
        }
    }

    .imgSmall {
        img {
            visibility: hidden;
            width: 0rem;
        }

    }
}



@media (max-width: 992px) {
    .navbar {
        flex-direction: row;
        justify-content: space-between;
        height: 8rem;

        .navbar-nav {
            margin: 0;
        }

        button {
            margin-left: 3rem;
            margin-top: 0.5rem;
        }

    }


    .imgSmall {
        img {
            visibility: inherit !important;
            width: initial !important;
            margin-right: 3rem;
            margin-top: 0.5rem;
        }
    }

    .versaNav {
        display: none !important;
    }

    .flexNav {
        flex-direction: column;
    }

}