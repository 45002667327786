.header {
    width: 100%;

    .imgHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;


        img {
            width: 80%;
            margin-top: 15rem;
        }

        .contactanos {
            left: 76%;
            bottom: 13%;
            position: absolute;

            button {
                height: 6rem;
                width: 24rem;
                position: relative;
                font-size: 3rem;
                clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 94% 20%, 94% 0%);

                a {
                    color: #7F0291;
                }

            }
        }

    }

    .pHeader1 {
        font-size: 3.2rem;
        margin-bottom: 8rem;
    }

    .pHeader2 {
        font-size: 2rem;
        margin-bottom: 4rem;
        margin-top: 4rem;
    }



    .lineaRender {
        margin-top: 4rem;
        width: 100%;
        height: 0.3rem;
        background-color: white;

    }

    .lineaRender2 {
        width: 100%;
        height: 0.3rem;
        background-color: white;

    }

    .whatsFlot {
        position: fixed;
        top: 80%;
        left: 85%;
        z-index: 100;
        background-color: #389506;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15.9rem;
        border-radius: 30px;
        color: white;
        text-align: right;
        text-decoration: none;
        font-family: Roboto, sans-serif;
        font-size: 1.5rem !important;
        font-weight: 700;

        img {
            width: 5rem;
            margin-left: 5px;
        }
    }

}

@media (max-width: 1475px) {
    h1 {
        font-size: 3.4rem;
    }

    .contactanos {
        left: 74% !important;


        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2.5rem !important;
            height: 4.8rem !important;
            width: 19.2rem !important;

        }
    }
}

@media (max-width: 1130px) {
    h1 {
        font-size: 3.2rem
    }


    .contactanos {
        left: 74% !important;
        bottom: 10% !important;



        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 2rem !important;
            height: 3.8rem !important;
            width: 15.6rem !important;

        }
    }
}

@media(max-width: 1075px){
    .whatsFlot{
        top: 88% !important; 
        left: 80% !important;
    }
}

@media (max-width: 855px) {
    h1 {
        font-size: 3re
    }

    .contactanos {
        left: 74% !important;
        bottom: 8% !important;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5rem !important;
            height: 3.1rem !important;
            width: 12.5rem !important;

        }
    }
}

@media (max-width: 803px) {
    .no-mobile{
        display: none;
    }

    .whatsFlot{
        width: 0rem !important;
        left: 88% !important;
    }
}

@media (max-width: 675px) {
    h1 {
        font-size: 2.8rem
    }
    .contactanos {
        left: 74% !important;
        bottom: 6% !important;

        button {
            display: flex;
            align-items: start;
            justify-content: center;
            font-size: 1.2rem !important;
            height: 2.3rem !important;
            width: 9.6rem !important;

            padding-top: 0.2rem;
        }
    }
}

@media (max-width: 519px) {
    h1 {
        font-size: 2.6rem
    }

    .contactanos {
        left: 70% !important;
        bottom: 5% !important;

    }
}

@media (max-width: 427px) {
    .contactanos {
        left: 69% !important;
        bottom: 4% !important;

        button {
            display: flex;
            align-items: start;
            justify-content: center;

            a {
                font-size: 1.2rem !important;
            }

            height: 1.9rem !important;
            width: 7.5rem !important;

            padding-top: 0.2rem;
        }

    }
}

@media (max-width: 350px) {
    h1 {
        font-size: 2.4rem;
    }

    .whatsFlot {
        position: fixed;
        top: 59% !important;
        left: 83% !important;

        img {
            width: 4.5rem !important;
        }
    }

    .pHeader1 {
        font-size: 2rem !important;
        margin-bottom: 2.5rem !important;
    }

    .contactanos {
        button {
            display: flex;
            align-items: start;
            justify-content: center;
        }

        left: 63% !important;
        bottom: 0% !important;
    }
}




// PRELOADER
.hidden {
    overflow: hidden;
}

.centrado {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom, rgba(255, 153, 0, 1), rgba(127, 2, 145, 1));
}

.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}