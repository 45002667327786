.clientes {
    margin-top: 20rem;
    scroll-margin-top: 15em;


    .titleClientes {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            margin-left: 1rem;
            margin-right: 1rem;
            color: #d04077ca;
            font-family: titulos;
            font-size: 1.9rem;
            text-align: center;
        }
    }

    .itemsClientes {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 5rem;

        color: rgb(255, 255, 255);


        .itemClient {
            background-color: rgba(255, 255, 255, 0.56);
            height: 28.4rem;
            width: 28.4rem;
            margin: 2rem;
            clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 11%, 90% 11%, 90% 0%);
        }
    }

}

.modal{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.servicioModal{
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
    margin-top: 3rem;
    h3{
        color: #7F0291 ;
    }
}

.titleServModal{
    display: flex;
    flex-direction: column;
    align-items: start;



    h3{
        margin-top: 1rem;
    }

}

.imgModal{
    margin-top: 4rem;
    display: flex;
    justify-content: center;
}
.linkCliente{
    padding: 0 !important;
    button{
        background-color: #7F0291;
        color: white;
        width: 18rem;
        height: 5rem;
        padding: 0.2 !important;
        margin-left: 10rem;
    }
}
.descrip-action{
    height: 15rem;
    display: flex;
    margin-top: 4rem;
}
.descriptionModal{
    background-color: #7F0291;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    h4{
        margin-bottom: 1rem;
        color: #FF9900;
        font-size: 1.8rem;
    }

    p{
        font-size: 2rem;
    }
}

.actionModal{
    background-color: #FF9900;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}