.nosotros {
    margin-top: 12rem;
    scroll-margin-top: 15em;
    height: auto;

    .titleNosotros {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            color: #b7308a;
            font-family: titulos;
            font-size: 1.9rem;
            text-align: center;
        }
    }

.alineacionNosotros{
    display: flex;
    margin-top: 10rem;
}
    .contentNosotros {
        margin-bottom: 16rem;

        h3 {
            font-size: 4rem;
            text-align: left;
            margin-left: 1.5rem;
        }
         p {
                    font-size: 2rem;
                    width: 100%;
                    text-align: start;
                    padding: 2rem;
                    margin-top: 2rem;
                }

        .misionNos, .equipoNos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
        
            .textoMision {
padding: 3rem;
               
            }
        }

        .equipo{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

.cardEquipo{
margin: 1.5rem;
    width: 17.5rem !important;
    height: 47rem;

}

.imgCard{
    width: 100%;
}

.dw{
    background-color: #800391;
}

.ux{
    background-color: #961D77
}

.cm{
    background-color: #9F286D
}

.contentEquipo{
h4{
    margin-top: 3rem;
}
}

           }
    }

}

@media (max-width: 1120px) {
    .nosotros {
        .contentNosotros{
            flex-direction: column;
            align-items: center;
        }
    }
}
@media (max-width: 842px) {

  .alineacionNosotros{
    flex-direction: column;
    align-items: center;
.contentNosotros{
       h3{
        text-align: center !important;
        margin-top: 2rem ;
    } 
}

  }

    .misionNos {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100% !important;
        }

        .textoMision {
            margin-bottom: 2rem;
            margin-right: 0rem !important;
            width: 100%;


            p {
                text-align: center !important;
                font-size: 1.8rem !important;
                width: 100% !important;

            }
    }
}
