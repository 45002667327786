.contacto {
    margin-top: 26rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    scroll-margin-top: 15em;

    .hiden {
        display: none;
    }

    h2 {
        font-family: letra;
        font-size: 5rem;
        text-align: center;
        color: rgba(127, 2, 145, 1);
        margin-bottom: 2rem;

    }

    .formulario {
        display: flex;
        flex-direction: column;
        align-items: center;

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: letra;
            font-size: 2rem;
            background-color: #7F0291;
            padding: 1.5rem;
            margin-bottom: 2rem;

            p {
                font-size: 2rem;
                padding: 1rem;
            }

            input {
                width: 126rem;
                height: 4rem;
                text-align: center;
                margin-bottom: 2rem;
                font-family: letra;
            }

            textarea {
                width: 126rem;
                font-family: letra;
                text-align: center;
                margin-bottom: 4rem;
            }

            textarea::placeholder {
                margin-top: 2rem;
                font-size: 2rem;
                color: #7F0291;
            }


            .nameForm {
                display: flex;
                margin-bottom: 1rem;

                input {
                    margin: 1rem;
                }
            }

            button {
                height: 4.7rem;
                width: 17rem;
                font-size: 2.2rem;
                color: #7F0291;
                background-color: rgba(255, 153, 0, 1);
                clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 94% 20%, 94% 0%);
            }
        }
    }

    .redesContacto {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;
        margin-bottom: 4rem;

        h4 {
            color: #7F0291;
            margin: 0rem 6rem 0rem 1.7rem;
        }
    }

    .whats {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;

        button {
            background-color: #7F0291;
            clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 20%, 94% 20%, 94% 0%);
            color: white;
            height: 4.7rem;
            width: 17rem;
            font-size: 2.2rem;

            a {
                color: white;
            }

        }
    }

}

.enviar-captcha{
    display: flex !important;
}

.g-recaptcha{
display: block;
}

// MENSAJE ENVIADO

.enviado{
    background: linear-gradient(to bottom, rgba(255, 153, 0, 1), rgba(127, 2, 145, 1));
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.enviadoBlank{
    background-color: white;
    height: 95vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 3rem;

    h2, h3{
        color: #7F0291;
    }
h2{
    font-size: 4rem;
    margin-bottom: 3rem;
    font-family: superstar;
}
    h3{
        font-size: 2.4rem;
        font-family: letra;
        margin-bottom: 5rem;
    }

    .imgEnviado{
        margin-bottom: 7rem;
    }

    .enviadoRedes{
        button{
            width: 20rem;
            background-color: #7F0291;
            color: white;
        }
    }
}

@media (min-width: 2300px) {
    .contacto {
        margin-top: 60rem;
    }
}

@media (min-width: 3000px) {
    .contacto {
        margin-top: 80rem;
    }
}

@media (max-width: 1363px) {

    .contacto {
        height: 90rem;
    }

    .nameForm {
        flex-direction: column;
    }

    form {

        input,
        textarea {
            width: 60rem !important;
        }
    }

}

@media (max-width: 835px) {
    .contacto {
        height: 110rem;
    }

    .redesContacto {
        width: 100%;
        flex-direction: column;
        align-items: center;

        img {
            margin-top: 2rem;
        }

        h4 {
            margin: 0 !important;
            text-align: center;
            margin-top: 2rem !important;
        }
    }
}

@media (max-width: 693px) {
    .contacto {
        height: 105rem;
    }

    h2 {
        font-size: 3.3rem !important;
    }

    form {

        input,
        textarea {
            width: 38rem !important;
        }
    }

}

@media (max-width: 451px) {
    .contacto {
        height: 100rem;
    }

    h2 {
        font-size: 2.9rem !important;
    }

    form {


        input,
        textarea {
            width: 27rem !important;
        }
    }

    .enviadoBlank{
        height: 100vh;
    }
}