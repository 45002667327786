* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;
scroll-behavior: smooth;
}

.app {
    width: 100%;
}

@font-face {
    font-family: "letra";
    src: url(../../public/fonts/SWIsop1.ttf);
    font-display: swap;
}

@font-face {
    font-family: "titulos";
    src: url(../../public/fonts/titulos.TTF);
    font-display: swap;
}

@font-face {
    font-family: "superstar";
    src: url(../../public/fonts/superstar.ttf);
    font-display: swap;
}


a {
    text-decoration: none;
    font-family: letra;
    font-size: 2.4rem !important;
    text-align: center;
}

ul{
    list-style: none;
}

p {
    text-align: center;
    font-family: letra;
    color: white;
    font-size: 2rem;

}

h1 {
    font-size: 3.6rem;
    text-align: center;
    color: white;
    font-family: titulos;

}

h2 {
    color: white;
    font-family: titulos;
    font-size: 2.1rem;
    text-align: center;
}

h3 {
    font-family: letra;
    font-size: 3.2rem;
    color: white;
    text-align: center;
}

h4 {
    font-family: letra;
    font-size: 2rem;
    color: white;
    text-align: center;
}

main {
    background: linear-gradient(to bottom, rgba(255, 153, 0, 1), rgba(127, 2, 145, 1));
}

.break {
    width: 100%;
    height: 45rem;

    img {
        width: 100%;
    }
}

button {
    font-family: letra;
    font-size: 2.2rem;
    background-color: rgba(255, 255, 255, 1);
    padding: 1rem;
    margin: 1rem;
    border: none;
}


.preload{
    background: linear-gradient(to bottom, rgba(255, 153, 0, 1), rgba(127, 2, 145, 1));
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
