.footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
    margin-top: 5rem;
    background-color: #d9d9d9c4;


    .contenedorFoot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        margin: 2rem;

        .imgFoot{
            margin-top: 1.7rem;
        }

        a {
            font-size: letra;
            margin: 0rem 2rem 0rem 0rem;
            color: #7F0291;

        }

        div {
            margin-top: 1em;
            margin-bottom: 1rem;
        }

        .siteMap {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 1rem;
        }

        .redesFoot {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-bottom: 0;
            margin-top: 2rem;

            .redes {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }

    .copyRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 2rem;

        .copyVersa {
            a {
                margin: 0;
            }

            p {
                font-size: 1.3rem;
                margin-top: 1.5rem;
                color: #7F0291;
            }
        }
    }

}